<div>
    <div class="d-flex flex-row-reverse">
        <div class="button-container">
            <button type="button" class="rg filter me-1 button-label" (click)="onFiltersClicked()"><i class="fa fa-filter" style="font-size: 1.5em" aria-hidden="true"></i></button>
        </div>
        <button type="button" class="rg me-1 export-print" (click)="export()">
            <i class="fa-solid fa-print" style="font-size: 1.5em"></i>
        </button>
        <button type="button" class="rg me-1 export-csv" (click)="exportToCSV()">
            <i class="fa-solid fa-file-csv" style="font-size: 1.5em"></i>
        </button>
        <button type="button" class="rg me-1 export-excel" (click)="exportToExcel()">
            <i class="fas fa-file-excel" style="font-size: 1.5em"></i>
        </button>
        <button type="button" class="rg me-1 export-pdf" (click)="exportToPDF()">
            <i class="fa-solid fa-file-pdf" style="font-size: 1.5em"></i>
        </button>
    </div>
    <h2 class="rg title m-2">{{ rgChartOption.title }}</h2>
    <div
        class="graphic-container"
        [ngStyle]="{
            'min-height': rgChartOption.isHorizontalBar ? rgChartOption.chartHeight + 'px' : 'null',
            'max-height': rgChartOption.isHorizontalBar ? rgChartOption.chartHeight + 'px' : 'null'
        }"
    >
        <apx-chart
            #chart
            [chart]="chartOptions.chart ? chartOptions.chart : { type: rgChartOption.chartType }"
            [annotations]="chartOptions.annotations ? chartOptions.annotations : {}"
            [colors]="chartOptions.colors ? chartOptions.colors : []"
            [dataLabels]="chartOptions.dataLabels ? chartOptions.dataLabels : {}"
            [series]="chartOptions.series ? chartOptions.series : []"
            [stroke]="chartOptions.stroke ? chartOptions.stroke : {}"
            [labels]="chartOptions.labels ? chartOptions.labels : []"
            [legend]="chartOptions.legend ? chartOptions.legend : {}"
            [markers]="chartOptions.markers ? chartOptions.markers : {}"
            [noData]="chartOptions.noData ? chartOptions.noData : {}"
            [fill]="chartOptions.fill ? chartOptions.fill : {}"
            [tooltip]="chartOptions.tooltip ? chartOptions.tooltip : {}"
            [plotOptions]="chartOptions.plotOptions ? chartOptions.plotOptions : {}"
            [responsive]="chartOptions.responsive ? chartOptions.responsive : []"
            [xaxis]="chartOptions.xaxis ? chartOptions.xaxis : {}"
            [yaxis]="chartOptions.yaxis ? chartOptions.yaxis : {}"
            [forecastDataPoints]="chartOptions.forecastDataPoints ? chartOptions.forecastDataPoints : {}"
            [grid]="chartOptions.grid ? chartOptions.grid : {}"
            [states]="chartOptions.states ? chartOptions.states : {}"
            [title]="chartOptions.title ? chartOptions.title : {}"
            [subtitle]="chartOptions.subtitle ? chartOptions.subtitle : {}"
            [theme]="chartOptions.theme ? chartOptions.theme : {}"
            [autoUpdateSeries]="chartOptions.autoUpdateSeries ? chartOptions.autoUpdateSeries : false"
        >
        </apx-chart>
    </div>
</div>
