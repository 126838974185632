import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { ApexAxisChartSeries, ApexNonAxisChartSeries, ChartComponent } from 'ng-apexcharts'
import * as XLSX from 'xlsx'
import { DataService } from './general-chart.service'
@Component({
    selector: 'app-general-chart',
    templateUrl: './general-chart.component.html',
    styleUrl: './general-chart.component.css'
})
export class GeneralChartComponent {

    @ViewChild('chart') chart : any

    @Input() data: RgGeneralChartData

    @Input() rgChartOption: RgGeneralChartOptions

    @Output() filtersClicked: EventEmitter<any> = new EventEmitter()

    chartOptions: Partial<ChartComponent>

    filterShow: boolean = false


    // public chart: Partial<ChartComponent>

    constructor (public ds: DataService) {


    }

    /**
     * This method is creating a single series based on the data passed
     * @param data
     * @returns
     */
    prepareIndividualSeriesData (data: RgGeneralChartSeries) {
        const indSeriesData: any = []
        data.main_axis.forEach((d, i) => {
            indSeriesData.push({ x: d, y: data.cross_axis[i] })
        })
        return indSeriesData
    }

    /**
     * This method is creating a single or multiple series based on the data passed
     * cross_axis will act as a series ( Y axis )
     * main_axis will act as a label ( X axis )
     * If multiple series are provided this method will process each series
     */
    prepareSeries () {
        const data = this.data
        const isMultipleSeries = Array.isArray(this.data)
        const series = []
        if (!isMultipleSeries) {
            series.push({ data: this.prepareIndividualSeriesData(data as RgGeneralChartSeries) })
        }
        else {
            const multiSeriesChartData = data as Array<RgGeneralChartSeries>
            multiSeriesChartData.forEach((data: RgGeneralChartSeries) => {
                series.push({ data: this.prepareIndividualSeriesData(data) })
            })
        }
        return series
    }

    filter () {

    }

    onFiltersClicked () {
        this.filtersClicked.emit()
    }

    /**
     * This method is initializing chart options after creating series to render on the screen
     *
     * In the case of NonAxis Charts
     * cross_axis will act as a series
     * main_axis will act as a label
     */
    prepareChartOptions () {
        let chartSeries = []
        let chartLabels: Array<string> = []
        if (this.rgChartOption.chartType == 'pie') {
            chartSeries = (this.data as RgGeneralChartSeries).cross_axis
            chartLabels = (this.data as RgGeneralChartSeries).main_axis as Array<string>
        }
        else {
            chartSeries = this.prepareSeries()
        }
        this.chartOptions = {
            series: chartSeries as ApexAxisChartSeries | ApexNonAxisChartSeries,
            chart: {
                height: this.rgChartOption.isHorizontalBar ? (this.data as RgGeneralChartSeries).cross_axis.length * 30 : this.rgChartOption.chartHeight,
                type: this.rgChartOption.chartType,
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    horizontal: this.rgChartOption.isHorizontalBar ? true : false,
                    dataLabels: {
                        position: this.rgChartOption.isPositionTop ? 'top' : '' // Positions the labels at the top of each bar
                    }
                }
            },
            dataLabels: {
                enabled: this.rgChartOption.isPositionTop ? true : false,
                offsetX: this.rgChartOption.isHorizontalBar ? 25 : 0, // Adjusts position to the right for horizontal bar
                offsetY: this.rgChartOption.isHorizontalBar ? 0 : -25, // Adjusts position above the bar
                style: {
                    fontSize: '12px',
                    colors: ['#000'] // Adjust the color of the label
                }
            },
            labels: chartLabels
        }
    }

    ngOnInit (): void {
        this.prepareChartOptions()
    }

    exportAsPng () {
        this.chart.dataURI().then((csv: any) => {
            const link = document.createElement('a')
            link.href = csv.imgURI
            link.download = 'chart.png'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        })
    }

    renderChart () {
        this.prepareChartOptions()
        this.chart.render()
    }

    exportToPDF () {
        const blob = new Blob([this.chart.chartObj.exports.getSvgString()], { type: 'image/svg+xml' })
        const svgFile = new File([blob], 'image.svg', { type: 'image/svg+xml' })
        const formData = new FormData()
        formData.append('file', svgFile)
        this.ds.svgToPDF(formData).subscribe(data => {
            const url = window.URL.createObjectURL(data)
            const a = document.createElement('a')
            a.href = url
            a.download = 'chart.pdf'
            a.click()
            window.URL.revokeObjectURL(url)
        })
    }

    export () {
        const currentData = this.data as RgGeneralChartSeries
        currentData.cross_axis.push(160)
        currentData.main_axis.push('Oct')
        this.renderChart()
    }

    exportToExcel () {
        const isMultipleSeries = Array.isArray(this.data)
        let excelFiledata: any = []
        // console.log(this.transpose(excelFiledata))
        if (isMultipleSeries) {
            const multiSeriesChartData = this.data as Array<RgGeneralChartSeries>
            multiSeriesChartData.forEach((data: RgGeneralChartSeries) => {
                excelFiledata.push(data.main_axis)
                excelFiledata.push(data.cross_axis)
            })
        }
        else {
            const data = this.data as RgGeneralChartSeries
            excelFiledata.push(data.main_axis)
            excelFiledata.push(data.cross_axis)
        }
        excelFiledata = this.transpose(excelFiledata)

        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(excelFiledata)
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

        XLSX.writeFile(wb, 'SheetJS.xlsx')
    }

    transpose (matrix: any[][]) {
        return matrix[0].map((_, colIndex) => matrix.map(row => row[colIndex]))
    }

    exportToCSV () {
        this.chart.chartObj.exports.ctx.exportToCSV()
    }


}

export interface RgGeneralChartOptions {
    chartType: RgGeneralChartType
    title?: string
    chartHeight?: number
    isHorizontalBar?: boolean
    isPositionTop?: boolean
}

export declare type RgGeneralChartType = 'line' | 'area' | 'bar' | 'pie' | 'donut' | 'radialBar' | 'scatter' | 'bubble' | 'heatmap' | 'candlestick' | 'boxPlot' | 'radar' | 'polarArea' | 'rangeBar' | 'rangeArea' | 'treemap'

export interface RgGeneralChartSeries {
    main_axis: Array<number | string | Array<number | string>>
    cross_axis: Array<number | string | Array<number | string>>
}

export type RgGeneralChartData = RgGeneralChartSeries | Array<RgGeneralChartSeries>
