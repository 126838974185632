import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { FormGroup, ValidationErrors } from '@angular/forms'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import moment from 'moment'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { map } from 'rxjs/operators'
import { apis } from '../../environments/environment'
import { CustomerSite } from '../interfaces/customer-site'
import { FormValidationErrors } from '../interfaces/form-validation-errors'
import { IAlertService } from '../libs/ialert/ialerts.service'
import { Customer } from '../models/customer'
import { Permission } from '../models/permission'
import { Role } from '../models/role'
import { User } from '../models/user'
import identifiers from './component-identifiers'
import { ConstantsService } from './constants.service'
import { LovService } from './lov.service'

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    baseUrl: string

    userLoggedInSource = new BehaviorSubject(false)

    scrollBottom: boolean

    scrollBottomChange = new Subject<boolean>()

    userImage = new Subject<string>()

    userLoggedInObs = this.userLoggedInSource.asObservable()

    /**
     * Component Identifiers
     */
    compIdentifiers = identifiers

    /**
     * This property is specifically required for Driver
     * related screens. All driver screens need to know, on which
     * site driver is currently present.
     */
    customerSite: CustomerSite | null = null

    userChanged: Subject<User> = new Subject()

    user: User

    lang: any

    /**
     * For common modules, logged-in customer details can be set & fetched
     * from this property
     */
    workshopSelectedCustomer: Customer | null = null

    constructor (
        public http: HttpClient,
        public cs: ConstantsService,
        private lov: LovService,
        private ts: TranslateService,
        private router: Router,
        private alert: IAlertService
    ) {
        this.baseUrl = apis.baseUrl + '/public'
        this.scrollBottom = false
        this.scrollBottomChange.subscribe(value => {
            this.scrollBottom = value
        })
        if (localStorage.getItem('token')) {
            this.user = JSON.parse(localStorage.getItem('user') as '')
            this.userLoggedInSource.next(true)
        }
        else {
            this.userLoggedInSource.next(false)
        }

        this.lang = this.translate('common')
        this.lang.subscribe((d: any) => {
            this.lang = d
        })
    }

    toggleScrollBottom (value: boolean): void {
        this.scrollBottomChange.next(value)
    }

    login (params: any): Observable<any> {
        const url = `${this.baseUrl}/login`

        return this.http.post<any>(url, params)
            .pipe(
                map(resp => {
                    if (resp && resp.success && resp.data.token) {
                        localStorage.setItem('token', resp.data.token)
                        localStorage.setItem('user', JSON.stringify(resp.data))
                        this.user = resp.data
                        this.userLoggedInSource.next(true)
                    }

                    return resp
                })
            )
    }

    googleLogin (): Observable<any> {
        const url = `${this.baseUrl}/login/${'google'}`

        return this.http.get<any>(url)
            .pipe(
                map(resp => {
                    if (resp && resp.success && resp.data.token) {
                        localStorage.setItem('token', resp.data.token)
                        localStorage.setItem('user', JSON.stringify(resp.data))
                        this.user = resp.data
                        this.userLoggedInSource.next(true)
                    }

                    return resp
                })
            )
    }

    register (params: any): Observable<any> {
        const url = `${this.baseUrl}/register`

        return this.http.post<any>(url, params)
            .pipe(
                map(resp => {
                    if (resp && resp.success && resp.data.token) {
                        localStorage.setItem('token', resp.data.token)
                        localStorage.setItem('user', JSON.stringify(resp.data))
                        this.user = resp.data
                        this.userLoggedInSource.next(true)
                    }

                    return resp
                })
            )
    }

    doUserRedirects (resp: any, router: Router) {
        // router.navigate(['/user/dashboard'])
        switch (resp.data.userable_type) {
        case ConstantsService.USER_ROLES.ADMIN: {
            router.navigate(['/admin/dashboard'])
            break
        }
        case ConstantsService.USER_ROLES.WORKSHOP: {
            router.navigate(['/workshop/dashboard'])
            break
        }
        case ConstantsService.USER_ROLES.CUSTOMER: {
            router.navigate(['/customer/dashboard'])
            break
        }
        }
    }

    logOut (): boolean {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        this.user.id = 0
        this.userLoggedInSource.next(false)

        return true
    }

    logOutSession (): Observable<any> {
        const url = `${this.baseUrl}/logout`

        return this.http.post<any>(url, { fcm_token: this.user.fcm_token })
    }

    /**
     * Checks if user holds any of the permission from the provided
     * list of permissions.
     *
     * @param permissions Array Permission names
     * @returns boolean
     */
    checkPermissions (permissions: Array<string>): boolean {

        if (this.user.userable_type === ConstantsService.USER_ROLES.ADMIN) {
            return true
        }

        let permissionFound = false
        permissions.map((permission: any) => {
            if (this.checkPermission(permission)) {
                permissionFound = true

                return
            }
        })

        return permissionFound
    }

    /**
     * Check if the logged in user holds the provided permission or not.
     *
     * It checks for all permissions
     *
     * @param permission string Permission Name
     * @returns boolean
     */
    checkPermission (permission: string): boolean {
        // return if user type is admin
        if (this.user.userable_type === ConstantsService.USER_ROLES.ADMIN) {
            return true
        }

        // return if user has role admin
        const checkUrserRole = this.user.roles.findIndex(r => r.name === ConstantsService.USER_ROLES.ADMIN)
        if (checkUrserRole > -1) {
            return true
        }

        // return if user has direct permission
        const index = this.user.permissions.findIndex((item: any) => item.name === permission)
        if (index > -1) {
            return true
        }

        // return if user has permission through current/default role
        const currentRolePermissions = this.getRolePermissions(this.user.current_role.id)
        if (!currentRolePermissions) {
            return false
        }

        const i: number = currentRolePermissions.findIndex((item: any) => item.name === permission)
        if (i > -1) {
            return true
        }

        return false
    }

    /**
     * Check if the logged in user hold the provided role or not
     * @returns boolean
     */
    checkAllRoles (role: string): boolean {
        if (!this.user || !this.user.roles) {
            return false
        }

        const checkUrserRole = this.user.roles.findIndex(r => r.name === role)
        if (checkUrserRole > -1) {
            return true
        }
        else {
            return false
        }
    }

    isAuthenticated (): boolean {
        if (localStorage.getItem('token')) {
            return true
        }

        return false
    }

    isCustomer (): boolean {
        if (this.isAuthenticated() && this.user.userable_type === ConstantsService.USER_ROLES.CUSTOMER) {
            return true
        }

        return false
    }

    isWorkshop (): boolean {
        if (this.isAuthenticated() && this.user.userable_type === ConstantsService.USER_ROLES.WORKSHOP) {
            return true
        }

        return false
    }

    isDriver (): boolean {
        if (this.isAuthenticated() /** && check if user has Driver role or not */) {
            return true
        }

        return false
    }

    isAdmin (): boolean {
        if (this.isAuthenticated() && this.user.userable_type === ConstantsService.USER_ROLES.ADMIN) {
            return true
        }

        return false
    }

    jsonToFormData (jsonObject: any, parentKey?: any, carryFormData?: FormData): FormData {

        const formData = carryFormData || new FormData()
        let index = 0

        // tslint:disable-next-line: forin
        for (const key in jsonObject) {
            if (jsonObject.hasOwnProperty(key)) {
                if (jsonObject[key] !== null && jsonObject[key] !== undefined) {
                    let propName = parentKey || key
                    if (parentKey && this.isObject(jsonObject)) {
                        propName = parentKey + '[' + key + ']'
                    }
                    if (parentKey && this.isArray(jsonObject)) {
                        propName = parentKey + '[' + index + ']'
                    }
                    if (jsonObject[key] instanceof File) {
                        formData.append(propName, jsonObject[key])
                    }
                    else if (jsonObject[key] instanceof FileList) {
                        for (let j = 0; j < jsonObject[key].length; j++) {
                            formData.append(propName + '[' + j + ']', jsonObject[key].item(j))
                        }
                    }
                    else if (this.isArray(jsonObject[key]) || this.isObject(jsonObject[key])) {
                        this.jsonToFormData(jsonObject[key], propName, formData)
                    }
                    else if (typeof jsonObject[key] === 'boolean') {
                        formData.append(propName, +jsonObject[key] ? '1' : '0')
                    }
                    else {
                        formData.append(propName, jsonObject[key])
                    }
                }
            }
            index++
        }

        return formData
    }

    getFormValidationErrors (form: FormGroup): FormValidationErrors {
        // console.log('%c ==>> Validation Errors: ', 'color: red; font-size:12px;');
        const response: FormValidationErrors = {
            totalErrors: 0,
            general: '',
            list: []
        }

        const errorMessages: any = {
            'required': (fieldName: string, params: any) => `${fieldName} is required.`,
            'maxlength': (fieldName: string, params: any) => `${fieldName} must not exceeds ${params.requiredLength} characters.`,
            'minlength': (fieldName: string, params: any) => `${fieldName} must have at least ${params.requiredLength} characters.`,
            'pattern': (fieldName: string, params: any) => `Invalid format for ${fieldName}`,
            'min': (fieldName: string, params: any) => `${fieldName} shouldn't be any number below ${params.min}`,
            'max': (fieldName: string, params: any) => `${fieldName} value shouldn't exceeds ${params.max}`,
            'whitespace': (fieldName: string, params: any) => `White spaces are not allowed in ${fieldName}`
        }

        Object.keys(form.controls).forEach(fieldName => {
            const controlErrors: ValidationErrors | null = form!.get(fieldName)!.errors
            if (controlErrors != null) {
                response.totalErrors++
                Object.keys(controlErrors).forEach(errorType => {
                    const field = fieldName.split('_')
                    field.forEach((f: string, i: number) => {
                        field[i] = this.snakeToCamel(f)
                    })
                    fieldName = field.join(' ')
                    response.list.push(errorMessages[errorType](fieldName, controlErrors[errorType]))
                    response.general = errorMessages[errorType](fieldName, controlErrors[errorType])
                })
            }
        })

        return response
    }

    snakeToCamel (str: string) {
        return str.replace(/_([a-z])/g, function (match, letter) {
            return letter.toUpperCase()
        }).replace(/^./, function (match) {
            return match.toUpperCase()
        })
    }

    isArray (val: any) {
        const toString = ({}).toString

        return toString.call(val) === '[object Array]'
    }

    isObject (val: any) {
        return !this.isArray(val) && typeof val === 'object' && !!val
    }

    checkVerificationCode (data: any): Observable<any> {
        const url = `${this.baseUrl}/verify-email`

        return this.http.post<any>(url, data)
    }

    resendVerificationCode (data: any): Observable<any> {
        const url = `${this.baseUrl}/resend-code`

        return this.http.post<any>(url, data)
    }

    checkResetCode (data: any): Observable<any> {
        const url = `${this.baseUrl}/verify-code`

        return this.http.post<any>(url, data)
    }

    resetPass (data: any): Observable<any> {
        const url = `${this.baseUrl}/reset-password`

        return this.http.post<any>(url, data)
    }

    translate (key: string) {
        return this.ts.get(key)
    }

    getCurrentLang () {
        let lang = localStorage.getItem('lang')
            ? localStorage.getItem('lang')
            : this.ts.getBrowserLang()
        if (!lang) {
            lang = 'en'
        }
        return lang
    }

    changeLanguage (lang: string) {
        this.ts.currentLang = lang
        localStorage.setItem('lang', lang)
        this.ts.setDefaultLang(lang)
        this.ts.use(lang)
        location.reload()
    }

    userImageUrl (userId?: number) {
        userId = userId ? userId : -1

        return `${apis.baseUrl}/public/profile-image/${userId}`
    }

    deepClone (obj: any) {
        if (obj == null || typeof obj !== 'object') {
            return obj
        }

        if (obj instanceof Date) {
            return new Date(obj)
        }
        if (obj instanceof String) {
            return new String(obj)
        }
        if (obj instanceof Number) {
            return new Number(obj)
        }
        if (obj instanceof Boolean) {
            return new Boolean(obj)
        }
        if (obj instanceof RegExp) {
            return new RegExp(obj)
        }

        // handle other objects if required
        let clone: any = {}
        if (obj instanceof Array) {
            clone = new Array(obj.length)
        }

        Object.keys(obj).map((key: any) => {
            clone[key] = this.deepClone(obj[key])
        })

        return clone
    }

    /**
     * Check if assetId enabled or not
     * @returns boolean
     */
    isAssetIdEnabled (cust: Customer | null = null): boolean {
        const customer = this.getCustomer(cust)
        return customer === false ? false : customer.enable_asset_id
    }

    /**
     * Deprecated. Should be removed
     * @param cust
     * @returns
     */
    isDropoffLocationEnabled (cust: Customer | null = null): boolean {
        return false
    }

    isHomeLocationEnabled (cust: Customer | null = null): boolean {
        const customer = this.getCustomer(cust)
        return customer === false ? false : customer.enable_home_location
    }

    isAssetDiscoveryPromptEnabled (cust: Customer | null = null): boolean {
        const customer = this.getCustomer(cust)
        return customer === false ? false : customer.asset_discovery_prompt
    }

    isRequestIdEnabled (cust: Customer | null = null): boolean {
        const customer = this.getCustomer(cust)
        return customer === false ? false : customer.enable_request_id
    }

    isSamePickupAndDropoffLocationsEnabled (cust: Customer | null = null): boolean {
        const customer = this.getCustomer(cust)
        return customer === false ? false : customer.enable_same_pickup_and_dropoff_location
    }

    isFixedPickupLocationEnabled (cust: Customer | null = null): boolean {
        const customer = this.getCustomer(cust)
        return customer === false ? false : customer.enable_fixed_pickup_location
    }

    isShippingEnabled (cust: Customer | null = null): boolean {
        const customer = this.getCustomer(cust)
        return customer === false ? false : customer.enable_shipping
    }

    isFixedDropoffLocationEnabled (cust: Customer | null = null): boolean {
        const customer = this.getCustomer(cust)
        return customer === false ? false : customer.enable_fixed_dropoff_location
    }

    isWorkOrderBlackListEnabled (cust: Customer | null = null): boolean {
        const customer = this.getCustomer(cust)
        return customer === false ? false : customer.enable_work_order_black_list
    }

    isWorkOrderWhiteListEnabled (cust: Customer | null = null): boolean {
        const customer = this.getCustomer(cust)
        return customer === false ? false : customer.enable_work_order_white_list
    }

    getCustomer (cust: Customer | null = null) {
        if (this.isCustomer()) {
            return this.user.userable as Customer
        }

        if (cust != null) {
            return cust
        }

        if (this.workshopSelectedCustomer != null) {
            return this.workshopSelectedCustomer
        }

        return false
    }

    /**
     * This method is only applicable for driver's panel.
     * When driver chooses a site for pickup or dropoff, details of
     * the site are stored in Local Storage. This method will retrieve
     * and return those details.
     *
     * @returns CustomerSite
     */
    getCustomerSite (): CustomerSite | null {
        const data = localStorage.getItem('customer-site-data')
        if (!data) {
            return null
        }

        return JSON.parse(data) as CustomerSite
    }

    caseNo (id: number): string {
        return `${this.lang.prefix}-${id.toString().padStart(4, '0')}`
    }

    tileNo (id: number, prefix?: string): string {
        if (prefix) {
            return `${prefix}-${id.toString().padStart(2, '0')}`
        }
        else {
            return id.toString().padStart(2, '0')
        }
    }

    getKeyByValue (object: any, value: string): string | undefined {
        return Object.keys(object).find(key => object[key] === value)
    }

    dateDiff (startDate: any, endDate?: any, format?: 'miliseconds') {
        const end = endDate == undefined ? moment(new Date) : moment(endDate)
        const start = moment(startDate)
        const duration = moment.duration(start.diff(end))

        if (format == 'miliseconds') {
            return duration.asMilliseconds()
        }

        return duration.humanize()
    }

    dateDiffMilli (startDate: any, endDate?: any) {
        this.dateDiff(startDate, endDate, 'miliseconds')
    }

    public playSound (name: string, ext: string = ''): void {
        // notification, ping
        const audio = new Audio()
        ext = !ext ? 'mp3' : ext
        audio.src = `assets/audios/${name}.${ext}`
        audio.load()
        audio.play()
    }

    changeRole (roleId: number) {
        if (this.user.current_role.id == roleId) {
            return
        }

        const role = this.user.roles.findById(roleId)
        if (role) {
            this.user.current_role = this.deepClone(role)
            localStorage.setItem('user', JSON.stringify(this.user))
            this.router.navigate(['/', this.user.userable_type])
            this.alert.success(this.lang.role_changed)
            this.userChanged.next(this.user)
        }
    }

    getRolePermissions (roleId: number) {
        const role = this.user.roles.findById(roleId)
        if (role) {
            return this.deepClone(role.permissions)
        }
        else {
            return []
        }
    }

    hasDriverPerimission (): boolean {
        // check for direct permission
        const index = this.user.permissions.findIndex((item: Permission) => item.permission_sub_type == 'driver')
        if (index > -1) {
            return true
        }

        // return if user has permission through current/default role
        const currentRolePermissions = this.getRolePermissions(this.user.current_role.id)
        const i = currentRolePermissions.findIndex((item: Permission) => item.permission_sub_type == 'driver')
        if (i > -1) {
            return true
        }

        return false
    }

    /**
     * This method will iterate through all the active roles
     * of logged-in user and return the highest role level
     * among all the roles.
     *
     * @returns number The Highest Role Level of this User
     */
    getMaxRoleLevel (): number {
        let maxRoleLevel = 0
        this.user.roles.map((role: Role) => {
            if (role.level > maxRoleLevel) {
                maxRoleLevel = role.level
            }
        })

        return maxRoleLevel
    }

    /**
     * There is some data which being store in browser's Local Storage
     * and in ApiService. It's being filled at the time of user's login only.
     *
     * This method can be called anytime to sysc that data manually.
     */
    syncUserData () {
        const url = `${apis.baseUrl}/${this.user.userable_type}/auth/user-data`

        return this.http.get<any>(url)
            .pipe(
                map(resp => {
                    if (resp && resp.success && resp.data.token) {
                        localStorage.setItem('token', resp.data.token)
                        localStorage.setItem('user', JSON.stringify(resp.data))
                        this.user = resp.data
                        this.userLoggedInSource.next(true)
                    }

                    return resp
                })
            )
    }

    /**
     * This method will udpate user token in the database
     * @param token
     * @returns
     */
    updateUserFcmToken (token: string) {
        const url = `${apis.baseUrl}/${this.user.userable_type}/fcm/register-token`

        return this.http.post<any>(url, { fcm_token: token })
    }

    /**
     * This method will publish notification for user in the browser
     * with a beep sound.
     *
     * @param title
     * @param msg
     * @param options
     */
    publishNotification (title: string, msg: string, options = null) {
        this.alert.info(msg, title, { interval: 25000, taIdentifier: 'notification-alert' })
        const tone: string = this.user.notification_tone ? this.user.notification_tone : 'default'
        this.playSound(`notifications/${tone}`)
    }

    removeUndefinedKeys<T extends object> (obj: T): Partial<T> {
        return Object.fromEntries(
        Object.entries(obj).filter(([_, value]) => value !== undefined && value != 'undefined')
    ) as Partial<T>
    }

    playSuccess (): void {
        this.playSound('success')
    }

    playError (): void {
        this.playSound('error')
    }

    playWarning (): void {
        this.playSound('warning')
    }
}
