import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { RgSelectUI } from './rg-select.interface'

@Component({
    selector: 'app-rg-select',
    templateUrl: './rg-select.component.html',
    styleUrls: ['./rg-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RgSelectComponent),
            multi: true
        }
    ]
})
export class RgSelectComponent<T> implements OnInit, ControlValueAccessor, OnChanges {
    /**
     * The actual input data of options provided by user to iterate over and print options of select
     * That can be array T or keyvalue pair
     */
    @Input() data: Array<T> | { [key: string]: string | number | boolean } = {}

    /**
     * Data for options. This array will be looped to print options.
     * User provided data is transformed to finally come up with options array
     */
    options: Array<T> = []

    /**
     * Key of the input data object that will be used to display text in options of select
     */
    @Input() textKey: keyof T

    /**
     * Key of input data object that will be used to pick value
     */
    @Input() valueKey: keyof T

    /**
     * This text will be displayed if no value is selected
     */
    @Input() placeholder: string = ''

    /**
     * Decides if the selected element is disabled or not
     */
    @Input() disabled: boolean = false

    /**
     * Decides if the provided options are keyvalue array
     */
    @Input() keyvalue: boolean = false

    /**
     * Selcted value of the component. This will be used for CDK e.g. Reactive Forms
     */
    value: string | number | boolean | undefined

    /**
     * OnChange method. Required for CDK
     * @param value
     */
    onChange = (value: string | number | boolean) => { 
    }

    /**
     * OnTouched method. Required for CDK
     */
    onTouched = () => { }

    /**
     * Search field is binded to this property. Used to filter search results
     */
    searchKeyword: string = ''

    /**
     *
     */
    @Output() itemSelected: EventEmitter<string | number | boolean> = new EventEmitter()

    /**
     * Element reference of rg-select
     */
    @ViewChild('rgSelect', { static: false }) refRgSelect: ElementRef

    /**
     * Element reference of dropdown-wrapper div
     */
    @ViewChild('dropdownWrapper', { static: false }) refDropdownWrapper: ElementRef

    /**
     * UI related items
     */
    ui: RgSelectUI = {
        collapse: false
    }

    constructor () { }

    ngOnInit (): void {
        this.init()
    }

    ngOnChanges (changes: SimpleChanges): void {
        // console.log('ngOnChanges changes', changes)
        if (changes['data']) {
            this.data = changes['data'].currentValue
            this.transformDataToOptions()
        }
    }

    /**
     * Transforms the keyvalue object into Array<{id: key, name: value}>
     */
    transformDataToOptions (): void {
        if (!this.data) {
            this.options = []
            return
        }
        
        if (this.keyvalue === false) {
            this.options = this.data as Array<T>
        }
        else {
            const dataAsObject = this.data as { [key: string]: string; }
            Object.keys(this.data).map( (key: string) => {
                this.options.push({
                    id: key,
                    name: dataAsObject[key] as string
                } as T)
            })

            this.textKey = 'name' as keyof T
            this.valueKey = 'id' as keyof T
        }
    }

    @HostListener('document:click', ['$event'])
    clickEvent (event: any) {
        const element: HTMLElement = event.target as HTMLElement
        const classes = (event.target as Element).className.split(' ')

        if (
            this.ui.collapse == true &&
            !(this.refRgSelect.nativeElement as HTMLElement).contains(element)
        ) {
            this.ui.collapse = false
            event.preventDefault()
            event.stopPropagation()
        }
    }


    init () {
        
    }

    getText (value: string | number | boolean | undefined) {
        const item = this.options.findByKey(this.valueKey as string, value)
        const text = item ? item[this.textKey] as string : undefined

        return text
    }

    /**
     * This is a handler method. It is called when user clicks on select box to open the dropdown
     */
    openDropdown () {
        if (this.disabled === false) {
            this.ui.collapse = true
        }
    }

    /**
     * This is a handler method. It is called when user selected a value
     */
    onItemSelected (item: T) {
        this.value = item[this.valueKey] as string
        this.itemSelected.emit(item[this.valueKey] as string)

        /**
         * Reactive Form Items:
         */
        this.onChange(item[this.valueKey] as string)
        this.onTouched()

        /**
         * Other or UI Items
         */
        this.ui.collapse = false
        this.searchKeyword = ''
    }

    /**
     * CDK Implementation
     */
    writeValue (value: string | number | boolean): void {
        this.value = value
    }

    registerOnChange (fn: any): void {
        this.onChange = fn
    }

    registerOnTouched (fn: any): void {
        this.onTouched = fn
    }

    /**
     * Implements disabled feature of FormControl.
     * This will set local disabled property if set from FormControl
     *
     * @param isDisabled This is the value comming from ReactiveForm's binded FormControl
     */
    setDisabledState (isDisabled: boolean): void {
        this.disabled = isDisabled
    }
}
