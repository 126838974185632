import { HttpClient, HttpEventType } from '@angular/common/http'
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core'
import { firstValueFrom, map } from 'rxjs'
import { LoadingStatusEnum } from 'src/app/enums/LoadingStatusEnum'
import { IAlertService } from 'src/app/libs/ialert/ialerts.service'
import { ApiService } from 'src/app/services/api.service'
import { ConstantsService } from 'src/app/services/constants.service'
import { apis } from 'src/environments/environment'
import { DialogConfigs, DialogService } from '../rg-dialog/dialog.service'
import { FileStatusEnum } from './../../enums/FileStatusEnum'
import { FileTypesEnum } from './../../enums/FileTypesEnum'
@Component({
    selector: 'app-rg-file-uploader',
    templateUrl: './rg-file-uploader.component.html',
    styleUrls: ['./rg-file-uploader.component.scss']
})
export class RgFileUploaderComponent implements OnInit {

    @Input() fd: FormData = new FormData()

    @Input() multipleUpload: boolean = false

    @Input() parallelLoading: boolean = false

    @Input() fileDeleteUrl: string = ''

    @Input() url: string

    @Input() options: RgFileUploaderOptions

    @Input() uploadActionAllowed: boolean = true

    @Input() config: RgFileUploaderOptions

    fileCount: number = 1

    @ViewChild('fileInputRef') fileInputRef: ElementRef

    @ViewChild('uploaderWrapper') uploaderWrapper: ElementRef

    /**
     * Index of files which are uploaded successfully
     */
    uploadedFileIndex: Array<number> = []

    /**
     * File Name and Image Source
     */
    selectedFileSrc: any = []

    /**
     * File Upload Status
     * Uploaded File Response
     * CurrentUploadedProgress
     */
    parallelUploadStatus: ParallelFileUploadStatus = {}

    /**
     * List of files selected by user
     */
    filesList: FileList | null = null

    validationResult: string | true

    singleUploadSelectedFileSrc: string = ''

    @Output()
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    onComplete = new EventEmitter()

    /**
     * UI Elements
     */
    waiting: {
        fileData: LoadingStatusEnum,
        bulkUpload: LoadingStatusEnum
    }

    lang: any

    singleImageLoadingWaiting: LoadingStatusEnum = LoadingStatusEnum.NONE

    /**
     * This will display the upload progress in percentage
     */
    uploadProgress: number = 0

    constructor (
        public alert: IAlertService,
        private renderer: Renderer2,
        private cdRef: ChangeDetectorRef,
        private api: ApiService,
        private http: HttpClient,
        public cs: ConstantsService,
        public dialogService: DialogService
    ) {
        this.initTranslation().then(this.initProperties.bind(this))
    }

    ngOnInit () {
    }

    initProperties () {
        this.waiting = {
            fileData: LoadingStatusEnum.NONE,
            bulkUpload: LoadingStatusEnum.NONE
        }
    }

    initTranslation (): Promise<any> {
        const transSub = this.api
            .translate('common.file-uploader')
            .pipe(
                map((d: any) => {
                    this.lang = d
                })
            )

        return firstValueFrom<any>(transSub)
    }

    triggerClickOnFile () {
        this.fileInputRef.nativeElement.click()
    }

    /**
     * Clear All the Local states of the component including the files in FileUploader component
     */
    clearData () {
        this.fd = new FormData()
        this.validationResult = ''
        this.selectedFileSrc = []
        this.parallelUploadStatus = {}
        this.filesList = null
        this.fileInputRef.nativeElement.value = ''
    }

    /**
     * This method will actually upload file to the provided URL
     * In case of multiple upload with no parallel loading and no multiple upload use uploadSingleMultiFile
     * In case of multiple upload with parallel loading use uploadMultiParallelFiles
     * @param files
     * @returns ture on success otherwise Error message in string
     */
    uploadFile (): true | string {
        if (!this.multipleUpload || (this.multipleUpload && !this.parallelLoading)) {
            return this.uploadSingleMultiFile()
        }
        return this.uploadMultiParallelFiles()
    }

    /**
     * Upload multiple files in parallel request and manage upload progress
     * of the individual files
     * @returns
     */
    uploadMultiParallelFiles (): true | string {
        // const validationResult = this.validateFile(this.filesList)

        // if (validationResult !== true) {
        //     this.onComplete.emit(validationResult)

        //     return validationResult
        // }
        this.validationResult = true
        if (this.filesList == null) {
            return 'invalid'
        }
        // multiple upload
        this.resetParallelUploadProgress()
        for (let i = 0; i < this.filesList.length; i++) {
            const file: File = this.filesList.item(i) as unknown as File
            const parallelUploadStatus = this.parallelUploadStatus
            this.fd.delete('file')
            this.fd.append('file', file, file.name)
            const url = `${apis.baseUrl}/${this.url}`
            this.http.post(url, this.fd, {
                reportProgress: true,
                observe: 'events'
            }).subscribe({
                next: (resp: any) => {
                    /**
                     * In case of upload complete decide whether the upload is successfull or not
                     */
                    if (resp.type === HttpEventType.Response) {
                        if (!resp.body.success) {
                            this.parallelUploadStatus[this.selectedFileSrc[i]['name']]['uploadStatus'] = FileStatusEnum.FAILED
                        }
                        else {
                            this.parallelUploadStatus[this.selectedFileSrc[i]['name']]['uploadStatus'] = FileStatusEnum.SUCCESS
                            this.uploadedFileIndex.push(i)
                        }
                        this.parallelUploadStatus[this.selectedFileSrc[i]['name']]['uploadResponse'] = resp
                        this.emitParallelUploadComplete()
                    }

                    /**
                     * In case of progress update the upload progress `state` in parallel upload status
                     */
                    if (resp.type === HttpEventType.UploadProgress) {
                        const percentDone = Math.round(100 * resp.loaded / resp.total)
                        this.parallelUploadStatus[this.selectedFileSrc[i]['name']] = { 'uploadProgess': percentDone, 'uploadStatus': FileStatusEnum.PENDING }
                    }
                },
                error: (error: any) => {
                    /**
                     * In case of error
                     * - update the upload status to failed
                     */
                    console.error('Upload failed:', error)
                    //File Content is too large to upload
                    if (error.status == 413) {
                        this.parallelUploadStatus[this.selectedFileSrc[i]['name']]['uploadStatus'] = FileStatusEnum.FAILED
                        this.parallelUploadStatus[this.selectedFileSrc[i]['name']]['uploadResponse'] = {
                            'body': {
                                'success': false,
                                'errors': {
                                    'general': this.lang.file_size_too_large
                                }
                            }
                        }
                        this.emitParallelUploadComplete()
                    }
                },
                complete: () => {
                    console.log('Upload completed')
                }
            })
        }


        return true
    }

    /**
     * In case all files are uploaded emit the complete event
     * with the details about each file uploaded like
     *  - file name (key)
     *  - file upload status
     *  - file upload response
     *  - file upload progress
     */
    emitParallelUploadComplete () {
        const parallelUploadProgess = Object.keys(this.parallelUploadStatus).every(key => {
            if (this.parallelUploadStatus[key]['uploadStatus'] != FileStatusEnum.PENDING) {
                return true
            }
            return false
        })

        if (parallelUploadProgess) {
            this.onComplete.emit(this.api.deepClone(this.parallelUploadStatus))
            //Remove the files which are successfully uploaded
        }

    }

    clearForm () {
        this.fd = new FormData()
    }

    /**
     * Remove the uploaded files from
     *  - FileList ( - this.filesList )
     *  - Parallel Upload Status ( - this.parallelUploadStatus)]
     *  - Selected File Source ( - this.selectedFileSrc)
     */
    // removeUploadedFiles () {
    //     const newSelectedFiles: any = []
    //     const newParallelUploadStatus: any = {}
    //     //Remove all the parallel upload Status which are success
    //     Object.keys(this.parallelUploadStatus).forEach((key: string) => {
    //         if (this.parallelUploadStatus[key]['uploadStatus'] != FileStatusEnum.SUCCESS) {
    //             newParallelUploadStatus[key] = this.parallelUploadStatus[key]
    //         }
    //     })
    //     //Only include those sources which are failed to upload
    //     this.selectedFileSrc.map((src: any) => {
    //         if (Object.keys(newParallelUploadStatus).includes(src.name)) {
    //             newSelectedFiles.push(src)
    //         }
    //     })

    //     // update the  file sources and file loading status
    //     this.parallelUploadStatus = newParallelUploadStatus
    //     this.selectedFileSrc = newSelectedFiles
    //     //remove the uploaded files from the FileList
    //     const dataTransfer = new DataTransfer()

    //     if (this.filesList) {
    //         // Loop through the FileList and add files except the one to remove
    //         Array.from(this.filesList).forEach((file, idx) => {
    //             if (Object.keys(this.parallelUploadStatus).includes(file.name)) {
    //                 dataTransfer.items.add(file)
    //             }
    //         })
    //     }
    //     this.filesList = dataTransfer.files
    // }

    /**
     * Reset the upload progress of the individual files when parallel loading
     */
    resetParallelUploadProgress () {
        Object.keys(this.parallelUploadStatus).map((key: string) => {
            this.parallelUploadStatus[key]['uploadProgess'] = 0
        })
    }

    /**
     * Upload all files in a single request
     * @returns
     */
    uploadSingleMultiFile (): true | string {
        // const validationResult = this.validateFile(this.filesList)

        // if (validationResult !== true) {
        //     this.onComplete.emit(validationResult)

        //     return validationResult
        // }
        this.validationResult = true
        // if (this.filesList == null) {
        //     return 'invalid'
        // }

        if (this.filesList && !this.multipleUpload) {
            const file: File = this.filesList.item(0) as unknown as File
            this.fd.append('file', file, file.name)
        }
        else if (this.filesList) { // multiple upload
            for (let i = 0; i < this.filesList.length; i++) {
                const file: File = this.filesList.item(i) as unknown as File
                this.fd.append('files[]', file, file.name)
            }
        }

        this.resetUploadProgress()

        const url = `${apis.baseUrl}/${this.url}`
        this.http.post(url, this.fd, {
            reportProgress: true,
            observe: 'events'
        }).subscribe({
            next: (resp: any) => {
                if (resp.type === HttpEventType.Response) {
                    this.onComplete.emit(resp.body)
                }

                if (resp.type === HttpEventType.UploadProgress) {
                    const percentDone = Math.round(100 * resp.loaded / resp.total)
                    this.uploadProgress = percentDone
                }
            },
            error: (error: any) => {
                console.error('Error during file upload:', error)
                if (error.status == 413) {
                    this.onComplete.emit({
                        'success': false,
                        'errors': {
                            'general': this.lang.file_size_too_large
                        }
                    })
                }
            },
            complete: () => {
                console.log('File upload completed')
            }
        })


        return true
    }

    reUploadFile () {
        this.fileInputRef.nativeElement.value = null
        this.cdRef.detectChanges()
        this.fileInputRef.nativeElement.click()
    }

    resetUploadProgress () {
        this.uploadProgress = 0
    }

    onDrop (e: any) {
        e.stopPropagation()
        e.preventDefault()
        this.renderer.removeClass(this.uploaderWrapper.nativeElement, 'dragging-over')

        if (!e.dataTransfer) {
            return this.alert.error(this.lang.unknown_load)
        }

        if (!e.dataTransfer.files || e.dataTransfer.files.length == 0) {
            return this.alert.error(this.lang.no_files)
        }
        /**
         * In case of multiple files use append mode
         */
        if (this.multipleUpload) {
            this.addFileToFileList(e.dataTransfer.files)
            this.manageMultiUploadFileChange(e.dataTransfer.files)
            this.fileInputRef.nativeElement.value = ''
        }
        /**
         * In case of single file overwrite the file
         */
        else {
            this.filesList = e.dataTransfer.files
            this.changeSingleUploadImageSrc()
        }
    }

    onDrag (e: any) {
        // e.stopPropagation();
        e.preventDefault()
    }

    onDragLeave (e: any) {
        e.preventDefault()
        this.renderer.removeClass(this.uploaderWrapper.nativeElement, 'dragging-over')
    }

    onDragOver (e: any) {
        e.preventDefault()
        this.renderer.addClass(this.uploaderWrapper.nativeElement, 'dragging-over')
    }

    /**
     * Clear All the Local states of the component except the files in FileUploader component
     */
    clearSelectedFiles () {
        this.selectedFileSrc = []
        this.validationResult = ''
        this.filesList = null
        this.parallelUploadStatus = {}
    }

    /**
     * Append newly selected files to the existing FileList
     * - Using DataTransfer API because elements.fileList by input = file is immutable
     *   So to append file in the existing FileList Add both existing file and selected
     *   files in a new FileList and assign it to this.filesList
     */
    addFileToFileList (selectedFiles: any) {
        const dataTransfer = new DataTransfer()

        // Add the existing files in the DataTransfer object
        if (this.filesList) {
            Array.from(this.filesList).forEach((file: any) => {
                dataTransfer.items.add(file)
            })
        }
        // Add the newly selected files in the DataTransfer object
        Array.from(selectedFiles).forEach((file: any) => {
            dataTransfer.items.add(file)
        })
        // Assign the updated FileList to this.filesList
        this.filesList = dataTransfer.files
    }

    /**
     * Called When user changes the file using file uploader
     * @param e
     * @returns
     */
    onFilesChange (e: Event) {
        const element = e.currentTarget as HTMLInputElement

        if (element.files && element.files.length == 0) return

        //If user uploading file more than allowed then show error
        if (this.multipleUpload && this.config && this.config.maxFilesCount) {
            if (element.files && element.files?.length > this.config.maxFilesCount) {
                this.alert.error(`${this.lang.maximum} ${this.config.maxFilesCount} ${this.lang.file_allowed}`)
                return
            }
        }

        /**
         * In case of multiple files use append mode
         */
        if (this.multipleUpload) {
            this.addFileToFileList(element.files)
            this.manageMultiUploadFileChange(element.files)
            this.fileInputRef.nativeElement.value = ''
        }
        /**
         * In case of single file overwrite the file
         */
        else {
            this.filesList = element.files
            this.changeSingleUploadImageSrc()
        }

        // this.validationResult = this.validateFile(this.filesList)
        this.validationResult = true
        this.resetUploadProgress()
    }

    confirmDeleteFile (event: any, index: number) {
        if (this.multipleUpload && this.parallelLoading && this.parallelUploadStatus[this.selectedFileSrc[index]['name']].uploadStatus == FileStatusEnum.PENDING) {
            return
        }
        event.stopPropagation()
        const dialog = this.dialogService.confirm({
            title: this.lang.confirm_attachment_delete,
            statement: this.lang.confirm_attachment_delete_stmt,
            confirm: {
                onClick: () => {
                    dialog.close()
                    this.deleteFile(event, index)
                }
            },
            decline: {
                onClick: () => {
                    dialog.close()
                }
            }
        } as DialogConfigs)
    }

    /**
     * TODO: Delete File endpoint need to imoplement
     */
    deleteFile (event: any, index: number) {
        this.deleteFileAtIndex(index)
        if (this.filesList && this.filesList.length == 0) {
            this.clearSelectedFiles()
            this.fileInputRef.nativeElement.value = ''
        }
    }

    deleteFileAtIndex (index: number) {
        const fileName = this.selectedFileSrc[index]['name']
        this.selectedFileSrc.splice(index, 1)
        // const fileName = this.filesList ? this.filesList.item(index)?.name : ''
        if (fileName) delete this.parallelUploadStatus[fileName]
        const dataTransfer = new DataTransfer()

        if (this.filesList) {
            // Loop through the FileList and add files except the one to remove
            Array.from(this.filesList).forEach((file, idx) => {
                if (idx !== index) {
                    dataTransfer.items.add(file) // Add file to the DataTransfer object
                }
            })
        }
        this.filesList = dataTransfer.files
        this.fileInputRef.nativeElement.value = ''
    }

    stopPropagation (event: any) {
        event.stopPropagation()
    }

    /**
     * TODO: need to imoplement
     */
    changeFile (event: any) {
        event.stopPropagation()
    }

    /**
     * This method will manage the preview to display
     * when multiple files are uploaded
     * In case of file type unknown it will convert the file to base64
     * and display the preview
     * In case of known display the preview from the assets
     */
    manageMultiUploadFileChange (files: any) {
        if (this.filesList) {
            const currentFileCount = this.selectedFileSrc.length
            for (let i = 0; i < files.length; i++) {
                this.selectedFileSrc.push({ name: files.item(i)?.name + ' - ' + this.fileCount, src: '', imageLoadingStatus: LoadingStatusEnum.NONE })
                this.fileCount += 1

            }

            for (let i = currentFileCount; i < this.selectedFileSrc.length; i++) {
                let key = this.filesList.item(i)?.name
                key = this.selectedFileSrc[i]['name']

                /**
                 * Initialze the parallel upload status for each file selected
                 */
                if (key) {
                    this.parallelUploadStatus[key] = {}
                    this.parallelUploadStatus[key]['uploadProgess'] = 0
                    this.parallelUploadStatus[key]['uploadStatus'] = FileStatusEnum.PENDING
                }
                /**
                 * If file type is not unknown and also not image display the static image from asset
                 */
                this.selectedFileSrc[i]['imageLoadingStatus'] = LoadingStatusEnum.FETCHING
                const fileType = this.getFileType(this.filesList.item(i))
                if ( fileType != 'unknown' && fileType != FileTypesEnum.image) {
                    this.selectedFileSrc[i]['src'] = `/assets/images/${fileType}.png?extra=${this.filesList.item(i)?.name}`
                    this.selectedFileSrc[i]['imageLoadingStatus'] = LoadingStatusEnum.DONE
                    continue
                }
                else if (fileType == FileTypesEnum.image) {
                    /**
                     * In case of image read the image and display to the user
                     */
                    const file = this.filesList[i]
                    const reader = new FileReader()
                    /**
                     * When the image is read the src will be set
                     */
                    reader.onload = (e: any) => {
                        this.selectedFileSrc[i]['src'] = e.target.result
                        this.selectedFileSrc[i]['imageLoadingStatus'] = LoadingStatusEnum.DONE
                    }
                    reader.readAsDataURL(file)
                }
                else {
                    /**
                     * When the file type image is not available in assets display the `others` image
                     */
                    this.selectedFileSrc[i]['src'] = `/assets/images/others.png?extra=${this.filesList.item(i)?.name}`
                    this.selectedFileSrc[i]['imageLoadingStatus'] = LoadingStatusEnum.DONE
                }
            }

        }
    }

    /**
     * This method will change the image src for single upload
     */
    changeSingleUploadImageSrc () {

        if (this.filesList) {
            this.singleImageLoadingWaiting = LoadingStatusEnum.FETCHING
            const fileType = this.getFileType(this.filesList.item(0))
            if (fileType != 'unknown' && fileType != FileTypesEnum.image) {
                this.singleImageLoadingWaiting = LoadingStatusEnum.DONE
                this.singleUploadSelectedFileSrc = `/assets/images/${fileType}.png?extra=${this.filesList.item(0)?.name}`
            }
            else if (fileType == FileTypesEnum.image) {
                const file = this.filesList.item(0)
                const reader = new FileReader()
                reader.onload = (e: any) => {
                    this.singleUploadSelectedFileSrc = e.target.result
                    this.singleImageLoadingWaiting = LoadingStatusEnum.DONE
                }
                if (file) {
                    reader.readAsDataURL(file)
                }
            }
            else {
                this.singleUploadSelectedFileSrc = `/assets/images/unknown.png?extra=${this.filesList.item(0)?.name}`
            }
        }
    }

    /**
     * This method will validate File
     *
     * @return Returns true if validated otherwise returns the
     * reason for being invalid in plain string
     */
    validateFile (files: FileList | null): true | string {
        if (!files) {
            return this.lang.no_file_selected
        }

        if (!this.multipleUpload && files.length > 1) {
            return this.lang.only_one_file
        }

        if (files.length == 0) {
            return this.lang.no_file_selected
        }
        for (let i = 0; i < files.length; i++) {
            const file = files[i]

            const result = this.isValidCsvFile(file)
            if (result !== true) {
                const excelResult = this.isValidExcelFile(file)
                if (excelResult !== true) {
                    return excelResult
                }
            }
            const maxAllowedSize = 1024 * 1024 * 50
            if (file.size > maxAllowedSize) {
                return this.lang.invalid_file_size
            }
        }


        return true
    }

    isValidExcelFile (file: File): true | string {
        const allowedExtensionsExcel = ['.xlsx', '.xls']

        const extension = file.name.substring(file.name.lastIndexOf('.'))
        if (allowedExtensionsExcel.indexOf(extension) < 0) {
            return this.lang.invalid_file
        }

        const allowedTypesExcel = [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
            'application/vnd.ms-excel' // xls
        ]
        if (allowedTypesExcel.indexOf(file.type) < 0) {
            return this.lang.invalid_file
        }

        return true
    }

    isValidCsvFile (file: File): true | string {
        const allowedExtensionsExcel = new Array('.csv')

        const extension = file.name.substring(file.name.lastIndexOf('.'))
        if (allowedExtensionsExcel.indexOf(extension) < 0) {
            return this.lang.invalid_file
        }

        const allowedTypesExcel = [
            'text/plain',
            'text/x-csv',
            'application/csv',
            'application/x-csv',
            'text/csv',
            'text/comma-separated-values',
            'text/x-comma-separated-values',
            'text/tab-separated-values'
        ]
        if (allowedTypesExcel.indexOf(file.type) < 0) {
            return this.lang.invalid_file
        }

        return true
    }

    getFileType (file: File | null) {
        if (file == null) {
            return 'unknown'
        }

        let resultFileType: FileTypesEnum | 'unknown' = 'unknown'

        if (file.type.startsWith('image/')) {
            resultFileType = FileTypesEnum.image
        }
        else {
            Object.keys(FileTypesEnum).map((type: string) => {
                if (type == 'image') return
                if ((this.cs.FILE_MIME_TYPES as any)[type].indexOf(file.type) > -1) {
                    resultFileType = type as unknown as FileTypesEnum
                }
            })
        }

        return resultFileType
    }

    get LoadingStatusEnum () {
        return LoadingStatusEnum
    }

    get FileStatusEnum () {
        return FileStatusEnum
    }
}

export interface RgFileUploaderOptions {
    /**
     * maximum allowed size of the file in MBs
     */
    maxSize?: number

    /**
     * Allowed file types, listed in an array
     */
    allowedFileTypes?: Array<string>

    /**
     *  Maximum number of files to be uploaded
     */
    maxFilesCount?: number
}

export interface ParallelFileUploadStatus {
    [key: string]: {
        uploadProgess?: number,
        uploadStatus?: FileStatusEnum,
        uploadResponse?: any,
    };
}
