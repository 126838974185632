import { Injectable } from '@angular/core'
import { DirTypeEnum } from '../enums/DirTypeEnum'
import { FaultTypeEnum } from '../enums/FaultTypeEnum'
import { PersistenceLevelEnum } from '../enums/PersistenceLevelEnum'
import { PrimaryScanEnum } from '../enums/PrimaryScanEnum'
import { RecordTypeEnum } from '../enums/RecordTypeEnum'
import { RepairPartStatusEnum } from '../enums/RepairPartStatusEnum'
import { WorkOrderLogTypeEnum } from '../enums/WorkOrderLogTypeEnum'
@Injectable({
    providedIn: 'root'
})
export class ConstantsService {
    public static USER_ROLES = {
        ADMIN: 'admin',
        CUSTOMER: 'customer',
        WORKSHOP: 'workshop'
    }

    public static USER_DYNAMIC_ROLES = {
        PLATFORM_ADMIN: 'Platform Admin',
        CUSTOMER_ADMIN: 'Customer Admin',
        WORKSHOP_ADMIN: 'Workshop Admin'
    }

    public LANGUAGES = {
        en: 'English',
        ps: 'Pashto'
    }

    public AUTOMATION_TYPE = {
        'None': 'None',
        'QC': 'QC Automation',
        'Claim': 'Claims Automation',
        'Tech': 'Tech Automation'
    }

    public DT_FORMATS = {
        DATE: 'DD-MMM-YYYY',
        TIME: 'hh:mm A',
        DATE_TIME: 'MMMM DD, YYYY hh:mm A',
        SHORT_DATE_TIME: 'DD-MMM-YYYY hh:mm A',
        DAY_DATE_TIME: 'dddd, MMMM DD, YYYY hh:mm A',
        DAY_DATE: 'dddd, MMMM DD, YYYY',
        BACKEND: 'YYYY-MM-DD',
        SHORT_DAY_DATE: 'ddd - MMM DD, YYYY',
        SHORT_DAY_DATE_TIME: 'ddd - MMM DD, YYYY hh:mm A'
    }

    public PER_PAGE = [25, 50, 100, 150, 200]

    public WARRANTY_TYPES = {
        CLW: {
            short: 'CLW',
            long: 'Customer Limited Warranty'
        },
        ADP: {
            short: 'ADP',
            long: 'Accidental Damage Protection'
        },
        BTW: {
            short: 'BTW',
            long: 'Batteries Warranty'
        }
    }

    public FAULT_TYPESS = {
        [FaultTypeEnum.CID]: 'CID',
        [FaultTypeEnum.DAMAGE]: 'Damage',
        [FaultTypeEnum.DEFECTIVE]: 'Defective'
    }

    public WO_LOG_TYPES = {
        PICKUP: WorkOrderLogTypeEnum.WoPickup,
        DIAGNOSTIC: WorkOrderLogTypeEnum.WoDiagnose,
        REPAIR: WorkOrderLogTypeEnum.WoRepair,
        CLAIM: WorkOrderLogTypeEnum.WoClaim,
        NOW_CLAIM: WorkOrderLogTypeEnum.WoNowClaim
    }

    public RECORD_TYPE = {
        DRAFT: RecordTypeEnum.Draft,
        FINISHED: RecordTypeEnum.Finished
    }

    public REPAIR_PART_STATUS = {
        INSTALLED: RepairPartStatusEnum.Installed,
        REMOVED: RepairPartStatusEnum.Removed,
        DELETED: RepairPartStatusEnum.Deleted
    }

    public PERSISTENCE_LEVEL = {
        NONE: PersistenceLevelEnum.None,
        EVERYTIME: PersistenceLevelEnum.Everytime,
        ONETIME: PersistenceLevelEnum.Onetime
    }

    public WARRANTY_PROVIDERS = {
        LENOVO: {
            id: 1,
            name: 'Lenovo',
            urlprefix: 'lenovo'
        },
        DELL: {
            id: 2,
            name: 'Dell',
            urlprefix: 'dell'
        },
        HP: {
            id: 3,
            name: 'Hp',
            urlprefix: 'hp'
        },
        CELL_TECH: {
            id: 4,
            name: 'Cell Tech',
            urlprefix: 'celltech'
        },
        WORTH_AVE: {
            id: 5,
            name: 'Worth Ave',
            urlprefix: 'worth-ave'
        },
        RUGGED: {
            id: 6,
            name: 'Rugged',
            urlprefix: 'rugged'
        },
        ACER: {
            id: 1001,
            name: 'Acer',
            urlprefix: 'acer'
        }
    }

    public CLAIM_TYPES = {
        NA: {
            id: 6
        }
    }

    public CLAIM_SUB_TYPES = {
        NA: {
            id: 3
        }
    }

    public ClaimDecisions = {
        Pending: 'Pending',
        Approved: 'Approved',
        Rejected: 'Rejected',
        AutoApproved: 'Auto Approved',
        AutoRejected: 'Auto Rejected'
    }

    public RULE_STYLUS = {
        Stylus: 'Stylus',
        NonStylus: 'Non-Stylus',
        Both: 'N/A'
    }

    public RULE_TOUCH = {
        Touch: 'Touch',
        NonTouch: 'Non-Touch',
        Both: 'N/A'
    }

    public Dir_TYPES = {
        [DirTypeEnum.NA]: 'Not Applicable',
        [DirTypeEnum.Dropped]: 'Dropped',
        [DirTypeEnum.LiquidDamage]: 'Liquid Damage'
    }

    public PRIMARY_SCAN = {
        [PrimaryScanEnum.Serial]: 'Serial',
        [PrimaryScanEnum.AssetID]: 'AssetID'
    }

    get (property: string, key: string) {
        return (this as any)[property as keyof ConstantsService][key]
    }

    public lat_lng = {
        defaultLat: 39.8283,
        defaultLng: -98.5795
    }

    public FILE_MIME_TYPES = {
        csv: [
            'text/plain',
            'text/x-csv',
            'application/csv',
            'application/x-csv',
            'text/csv',
            'text/comma-separated-values',
            'text/x-comma-separated-values',
            'text/tab-separated-values'
        ],
        excel: [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
            'application/vnd.ms-excel' // xls
        ],
        pdf: [
            'application/pdf'
        ],
        docs: [
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ],
        txt: [
            'text/plain'
        ]
    }
}
