<div class="rg-select" [class.open]="ui.collapse" #rgSelect>
    <div class="selected-item" (click)="openDropdown()">
        <div class="value">{{ getText(this.value) || placeholder }}</div>
        <div class="icon">
            <img src="assets/images/down-angle.svg" alt="v" />
        </div>
    </div>

    <div class="dropdown-wrapper" #dropdownWrapper>
        <input type="text" [(ngModel)]="searchKeyword" placeholder="Search here..." />
        <div class="dropdown">
            @for (item of options | filter: searchKeyword; track item) {
                <div class="item" (click)="onItemSelected(item)">{{ item[this.textKey] }}</div>
            }
        </div>
    </div>
</div>
