import { Component, Input, OnInit } from '@angular/core';
@Component({
    // eslint-disable-next-line
    selector: '[skeletonTabelLoader]',
    templateUrl: './skeleton-tabel-loader.component.html',
    styleUrls: ['./skeleton-tabel-loader.component.css']
})
export class SkeletonTabelLoaderComponent implements OnInit {
    @Input()
    skeletonTabelLoader: Options

    rows: Array<number> = []

    cols: Array<{ colSpan: number; colWidth: string }> = []

    constructor () {
    }

    ngOnInit () {
        for (let i = 0; i < this.skeletonTabelLoader.rows; i++) {
            this.rows.push(i)
        }

        for (let i = 0; i < this.skeletonTabelLoader.cols; i++) {
            const colSpan = this.skeletonTabelLoader.colSpans[i] || 1
            const colWidth = this.skeletonTabelLoader.colWidths[i] || 'auto'
            this.cols.push({ colSpan, colWidth })
            i += colSpan - 1
        }

    }

}

export class Options {
    cols: number = 1

    rows: number = 1

    colSpans: Array<number> = []

    colWidths: Array<string> = []
}
